import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import styled from 'styled-components'

// Images
import ButtonDefault from 'components/elements/ButtonDefault'

const customStyles = {
  overlay: {
    zIndex: 9,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
}

const Button = styled.div`
  background-color: ${props => props.theme.color.contrast};
  display: inline-block;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    font-family: ${props => props.theme.font.family.secondaryAlt};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.light} !important;
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`

const CustomIframe = styled.iframe`
@media (max-width: 767px){
    width: 350px !important;
}
`

function App({ children, buttonText }) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className="ervaring">
      <Button onClick={openModal} type="button">
        <span>{buttonText}</span>
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <CustomIframe
          src={children}
          height="550"
          width="400"
          title="Voordeel berekenen"
          scrolling="0"
        />
      </Modal>
    </div>
  )
}

export default App
